<template>
    <main>
    <modalInformacionUsuario :usuario="usuario"></modalInformacionUsuario>
    <page-header-compact></page-header-compact>
    <div class="container-xl px-4 mt-n15">
      <div class="card ">
        <cardInformacionUsuario :usuario="usuario"></cardInformacionUsuario>
      </div>
      <div class="card mt-2">
          <div class="card-body">
          <div class="table-responsive">
              <table class="table table-striped table-bordered">
                  <tr>
                      <th>Pregunta</th>
                      <th>Opciones</th>
                  </tr>
                  <tr v-for="pre in preguntas" :key="pre.id">
                      <td>{{pre.descripcion}}</td>
                      <td>
                            <select class="form-control" v-model="pre.respuesta">
                                <option v-for="opc in pre.opciones" :key="`${opc.id}_opc`" :value="opc.valor">
                                    {{opc.opcion}}
                                </option>
                            </select>
                      </td>
                  </tr>
              </table>
              <div class="form-group">
                  <label class="form-label">Puntaje Total</label>
                  <input type="number" class="form-control" readonly v-model="puntaje_total">
              </div>
              </div>
          </div>
      </div>
      <div class="row mt-2">
          <div class="col-12">
              <button class="btn btn-success" @click="guardar()">
                  <i class="fas fa-save fa-fw text-white-50"></i>
                  Guardar
              </button>
          </div>
      </div>
    </div>
  </main>
</template>
<script>
import modalInformacionUsuario from "../../base/informacionUsuario/modalInformacionUsuario";
import cardInformacionUsuario from "../../base/informacionUsuario/cardInformacionUsuario";
import usuarioService from '../../../../services/usuarioService';
import preguntasApgarFamiliarService from '../../../../services/preguntasApgarFamiliarService';
import valoracionApgarFamiliarService from '../../../../services/valoracionApgarFamiliarService';
import PageHeaderCompact from "../../../../components/layouts/content/page-header-compact";
import Swal from "sweetalert2";

export default {
    components:{cardInformacionUsuario,modalInformacionUsuario,PageHeaderCompact},
    data() {
        return {
            usuario: {},
            preguntas: [],
        };
    },
    methods: {
        async cargarUsuario(id) {
            const response = await usuarioService.show(id);
            this.usuario = response.data;
        },
        async cargarPreguntas(){
            const response = await preguntasApgarFamiliarService.index();
            this.preguntas = response.data.map(p => {
                return {
                    ...p,
                    respuesta : '',
                   
                }
            });
        },
        async guardar() {
      try {
        let valoracion_apgar = {
            puntaje_total:this.puntaje_total,
            id_usuario: this.$route.params.id,
            respuestas: this.preguntas,
        };

        this.LoaderSpinnerShow();
        
        await valoracionApgarFamiliarService.store(valoracion_apgar);

        this.LoaderSpinnerHide();
        
        await Swal.fire("Datos guardados con exito", "", "success");
        this.$router.push({name:'historias.perfil.usuario',params:{idUsuario:this.$route.params.id}});
      } catch (e) {
          console.error(e);
          this.LoaderSpinnerHide();
          Swal.fire("Ups!", "ha ocurrido un error al procesar la solicitud", "error");
      }
    },
    },
    async created() {
        await this.cargarUsuario(this.$route.params.id);
        this.cargarPreguntas();
    },
    computed:{
        puntaje_total: function () {
            return this.preguntas.reduce((t,x)=> {
                return t + (x.respuesta !== '' ? Number(x.respuesta) : 0);
            },0);
        }
    }
}
</script>